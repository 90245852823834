import React from "react";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Modal } from 'flowbite-react';
import axios from "axios";

const DefaultModal = ({ openModal, setOpenModal, setAgree }) => {
    // const props = { openModal, setOpenModal };

    return (
        <>
            <Modal show={openModal === true}>
                <Modal.Header>개인정보수집 및 활용동의</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <strong>1. 개인정보 수집 및 이용목적</strong> <br />
                        <br />
                        가. 수집방법<br />
                        - 보험비교견적신청, 전화번호를 통한 직접 문의<br />
                        나. 이용목적<br />
                        - 고지의 전달, 불만처리나 서비스/이벤트 정보 안내 등 원활한 의사소통 경로의 확보, 이벤트 및 상담을 위해 이용 할 수도 있습니다.<br />
                        - 보험상품안내, 보험가입안내(전화, 문자, 카카오톡, e-mail)<br />
                        다. 서비스 이용과정에서 아래와 같은 정보들이 간접적으로 수집될 수 있습니다.<br />
                        - IP주소, 서비스 이용기록, 접속 로그, 쿠키<br />
                        <br />
                        <strong>2. 개인정보 수집항목<br /></strong>
                        <br />
                        - 이름, 연락처, 생년월일, 주민등록번호(목적달성 후 즉시 파기), 기존보험사, 희망보험사, 가입담보, 희망담보, 담보설정시 필요한 필수정보<br />
                        <br />
                        <strong>3. 보유기간 및 이용기간<br /></strong>
                        <br />
                        회사에 제공하는 서비스를 이용하는 동안 회사는 정보주체들의 개인정보를 5년간 보유 및 이용합니다. 본 동의는 언제든지 철회할 수 있으며, 개인정보의 이용과 관련하여 고객님께 불편을 드린 경우 언제라도 연락 주시면 신속하게 조치하겠습니다. 고객님의 정보 보호에 최선의 노력을 다할 것을 약속 드립니다.<br />
                        <br />
                        단, 상법 등 관련법령의 규정에 의하여 다음과 같이 거래관련 권리의무관계의 확인 등을 이유로 일정기간 보유하여야 할 필요성이 있는 경우에는 예외적으로 일정기간 보유하게 됩니다.<br />
                        가. 계약 또는 청약철회 등에 관한 기록<br />
                        - 보존기간 : 5년<br />
                        나. 대금결제 및 재화 등의 공급에 관한 기록<br />
                        - 보존기간 : 5년<br />
                        다. 소비자의 불만 또는 분쟁처리에 관한 기록<br />
                        - 보존기간 : 3년<br />
                        라. 신용정보의 수집, 처리 및 이용 등에 관한 기록<br />
                        - 보존기간 : 3년<br />
                        마. 접속에 관한 기록보존<br />
                        - 보존기간 : 3개월<br />
                        <br />
                        회사는 귀중한 회원의 개인정보를 안전하게 처리하며, 유출의 방지를 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다.<br />
                        - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br />
                        - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br />
                        <br />
                        <strong>4. 개인정보 위탁처리<br /></strong>
                        <br />
                        당사는 회원님의 동의없이 개인정보를 위탁하지 않습니다.<br />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setAgree('on');
                        setOpenModal(false);
                    }}>동의하고 창 닫기</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function Main() {
    const [openModal, setOpenModal] = useState(false);

    // form
    const [name, setName] = useState("");
    const [callOfNum, setCallOfNum] = useState("");
    const [referee, setReferee] = useState("");
    const [agree, setAgree] = useState(false);

    const [agreeStatus, setAgreeStatus] = useState("");

    const [companyInfo, setCompanyInfo] = useState([]);
    const [bottomText, setBottomText] = useState();
    const [webTitle, setWebTitle] = useState();
    const [webDesc, setWebDesc] = useState();
    const [mainImageSrc, setMainImageSrc] = useState();

    async function submit() {
        if (!agree) {
            setAgreeStatus("약관은 필수 동의 사항입니다.");
            return;
        }
        else {
            setAgreeStatus("");
        }

        if (!name) {
            return;
        }
        else {
        }

        const formData = new FormData();
        formData.append("name", String(name));
        formData.append("callOfNum", String(callOfNum));
        formData.append("referee", String(referee));
        formData.append("agree", String(agree));

        // console.log(name, callOfNum,referee, agree)

        // console.log(...formData)

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: formData => formData
        }

        const result = await axios.post('http://oneshot114.com/manager/forClient/addUserInput.php', formData, config).then((res) => {
            console.log(res)
            alert("견적 신청이 완료되었습니다.")
        })
    }

    // 이름 검사
    const replaceChar = /[~!@#$%^&*()\-=_';<>/.`:\\,[\]?|{}]/gi;
    const replaceNotFullKorean = /[ㄱ-ㅎㅏ-ㅣ]/gi;

    const handleFocusOutByName = (e) => {
        let x = e.target.value;
        if (x.length > 0) {
            if (x.match(replaceChar) || x.match(replaceNotFullKorean)) {
                x = x.replace(replaceChar, '').replace(replaceNotFullKorean, '');
                setName(x);
            }
        }
    };

    const handleKeyUpByName = (e) => {
        const { value } = e.target;
        setName(value.replace(replaceChar, ''));
    };

    // 전화번호 검사
    const handleKeyDownByCallNum = (e) => {
        const transNum = e.target.value.replace(/-/gi, '');
        const k = e.keyCode;

        if (transNum.length >= 11 && ((k >= 48 && k <= 126) || (k >= 12592 && k <= 12687 || k === 32 || k === 229 || (k >= 45032 && k <= 55203)))) {
            e.preventDefault();
        }
    };

    const handleBlurByCallNum = (e) => {
        const { value } = e.target;
        if (value === '') return;

        let transNum = value.replace(/-/gi, '');
        if (transNum !== null && transNum !== '') {
            if (transNum.length === 11 || transNum.length === 10) {
                const regExpCtn = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})([0-9]{3,4})([0-9]{4})$/;
                if (regExpCtn.test(transNum)) {
                    transNum = transNum.replace(/^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/, "$1-$2-$3");
                    e.target.value = transNum;
                } else {
                    e.target.value = "";
                    e.target.focus();
                }
            } else {
                e.target.value = "";
                e.target.focus();
            }
        }
    };

    //  추천인 이름 검사
    const handleFocusOutByReferee = (e) => {
        let x = e.target.value;
        if (x.length > 0) {
            if (x.match(replaceChar) || x.match(replaceNotFullKorean)) {
                x = x.replace(replaceChar, '').replace(replaceNotFullKorean, '');
                setReferee(x);
            }
        }
    };

    const handleKeyUpByReferee = (e) => {
        const { value } = e.target;
        setReferee(value.replace(replaceChar, ''));
    };

    function Mobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const getClientInfo = async () => {
        await axios.get('/manager/forClient/client_ajax.php')
            .then((res) => {
                const info = res.data.company;
                const text = res.data.web.bottomText;
                setCompanyInfo(info);
                setBottomText(text);

                setWebTitle(res.data.web.webTitle);
                setWebDesc(res.data.web.webDesc);

                if (Mobile()) {
                    setMainImageSrc(res.data.web.mbanner);
                }
                else {
                    setMainImageSrc(res.data.web.banner);
                }
            });
    }

    useEffect(() => {
        getClientInfo();
        console.log(Mobile())
    }, [])

    return (
        <React.Fragment>
            {/* 메인 배너 */}
            <div
                style={{
                    backgroundImage: 'linear-gradient(113deg, #70A0E4 18.4%, #3E7DD7 68.6%)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                className="bg-gray-100 md:h-[40vh]
                flex items-center justify-center
                ">
                <div className="p-5">
                    <img className="md:h-[35vh]" src={`${mainImageSrc}`} />
                    {/* <img
                        className="sm:h-main-banner py-1 p-4"
                        src="./타이틀.png" />
                    <div className="flex items-center justify-center
                    max-md:flex-col md:flex-row">
                        {
                            ['1', '2', '3'].map((item) => {
                                return (
                                    <img
                                        className="sm:h-main-banner 
                                    max-md:w-34 max-md:mt-6
                                    md:w-56 md:mx-3"
                                        src={`./내용-${item}.png`} />
                                )
                            })
                        }
                    </div> */}
                </div>
            </div>

            {/* 서브 배너 */}
            <div className="p-0 max-w-[1100px]">
                <div className="bg-gray-200 border rounded-2xl 
            md:h-[10vh] md:mt-[2.5vh] max-md:my-2 md:mx-5 max-md:mx-2
            flex max-md:flex-col md:flex-row
            md:items-center md:justify-center
            max-md:p-3 
            max-md:justify-start max-md:items-center">
                    {
                        ['1', '2', '3'].map((item) => {
                            return (
                                <img
                                    className="
                                md:h-6
                                max-md:h-6
                                max-md: my-3
                                "
                                    src={`./미니-${item}.png`} />
                            )
                        })
                    }
                </div>
            </div>

             {/* 견적 비교 폼 */}
             <div className="md:h-[20.5vh] flex items-center justify-center
            max-w-[750px]
            max-md:flex-col">
                {/* 이미지 */}
                <div className="text-3xl md:text-right max-md:text-center
                max-md:my-3 max-md:mb-6 md:mr-5">
                    <div>
                        자동차보험 <br />
                        <b>통합견적 비교</b>
                    </div>
                </div>
                {/* 폼 */}
                <div className="">
                    <table className="">
                        <tr>
                            <td>
                                이름(필수)
                            </td>
                            <td className="pl-2">
                                <input
                                    className="border border-1 border-gray-300 rounded-md px-4 py-2"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} onBlur={(e) => handleFocusOutByName(e)}
                                    onKeyUp={(e) => handleKeyUpByName(e)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                전화번호(필수)
                            </td>
                            <td className="pl-2">
                                <input
                                    className="border border-1 border-gray-300 rounded-md px-4 py-2"
                                    type="text"
                                    onChange={(e) => setCallOfNum((e.target.value))}
                                    onKeyDown={(e) => handleKeyDownByCallNum(e)}
                                    onBlur={(e) => handleBlurByCallNum(e)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                추천인(선택)
                            </td>
                            <td className="pl-2">
                                <input
                                    className="border border-1 border-gray-300 rounded-md px-4 py-2"
                                    type="text"
                                    value={referee}
                                    onChange={(e) => setReferee((e.target.value))}
                                    onBlur={(e) => handleFocusOutByReferee(e)}
                                    onKeyUp={(e) => handleKeyUpByReferee(e)} />
                            </td>
                        </tr>
                    </table>
                </div>
                {/* 버튼 레이아웃 */}
                <div className="flex flex-col">
                    {/* 버튼 */}
                    <div>
                        <button className="bg-gray-400 
                        max-md:p-4 max-md:w-[200px] max-md:mt-4
                        md:p-7 md:py-12 border rounded-xl text-xl text-white font-semibold"
                            onClick={() => submit()}>
                            견적 신청
                        </button>
                    </div>
                    {/* 개인정보 활용동의 */}
                    <div className="flex items-center mt-2
                    text-center max-md:mb-4">
                        <input type="checkbox"
                            checked={agree} />
                        <span className="ml-1 cursor-pointer"
                            onClick={() => setOpenModal(true)}>개인정보 활용동의 (필수)</span>
                    </div>
                    <div className="text-sm text-red-600 font-semibold">
                        {agreeStatus}
                    </div>
                    {
                        openModal
                            ?
                            <DefaultModal
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                                setAgree={setAgree}
                            />
                            :
                            null
                    }
                </div>
            </div>
            
            {/* 자동차보험 바로 계산하기 */}
            <div className="md:h-[27vh] p-4">
                {/* 타이틀 */}
                <div className="text-4xl max-md:text-2xl font-bold text-center">
                    자동차 보험 바로 계산하기
                </div>
                <div className="max-w-[1100px] p-4 flex max-md:flex-col">
                    {
                        companyInfo.map((item, idx) => {
                            return (
                                <React.Fragment>
                                    <div className="p-3 bg-white border rounded-xl flex flex-col
                                    md:w-1/6 flex-wrap
                                    max-md:w-full max-md:mb-4">
                                        {/* 이미지 */}
                                        <img
                                            className="h-9"
                                            src={`${item.imageUrl}`} />

                                        {/* 보험사 */}
                                        <div className="text-center">
                                            <b>{item.name} <br /></b>
                                            다이렉트자동차보험
                                        </div>

                                        {/* 계산 버튼 */}
                                        <a href={`${item.calcUrl}`}
                                        target="_blank">
                                            <button className="bg-blue-500
                                            border rounded-md p-2 text-white mt-1">
                                                보험료계산
                                            </button>
                                        </a>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </div>


            </div>

            {/* footer */}
            <div dangerouslySetInnerHTML={{ __html: bottomText }}
                className="text-center bg-[#272727] text-white text-sm p-5 mt-10">
            </div>

        </React.Fragment>
    )
}

export default Main;