import './App.css';
import "./index.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Main from "./main.js";

function App() {
  return (
    <React.Fragment>
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </div>
  </React.Fragment>
  );
}

export default App;
